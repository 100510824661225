import React, { useEffect, useState, createRef } from 'react';
//import { makeStyles } from '@material-ui/core/styles';
//import Grid from '@material-ui/core/Grid';
import Grid from '@mui/material';
import * as DatabaseService from '../../services/DatabaseService';
//import CategoryCounter from '../../components/CategoryCounter';
//import BarChart from '../../components/charts/BarChart';
//import processChartData from '../../components/charts/processChartData';

import TablePaginationActions from '../../components/TablePaginationActions'; // Adjust the path accordingly
import SearchInput from '../../components/SearchInput';

// Table Libraries
import PropTypes from 'prop-types';
import { alpha, useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

/* Dialog Box */
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
/* End of Dialog Box */

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StorageIcon from '@mui/icons-material/Storage';

import Button from '@mui/material/Button';
import { display } from '@mui/system';
// Table Libraries


export default function ExtractorPage() {

  function createData(id, lg, gop_snr, art, haeufigkeit_gop, wertigkeit, wertigkeit_nach, gesamtvolumen) {
    return {
      id, 
      lg, 
      gop_snr, 
      art, 
      haeufigkeit_gop, 
      wertigkeit, 
      wertigkeit_nach, 
      gesamtvolumen
    };
  }

  /*let rows = [
                createData('1','01/00','01100','GRÃœN','5','5','0,29','0,31','196,0','196,0','980,0',''),
                createData('2','01/00','01102','ROT','1','1','0,06','0,05','101,0','101,0','101,0',''),
                createData('3','01/00','08220','ROSAROT','1.430','1.430','81,53','66,47','24,0','24,0','34.320,0','')
             ];*/
  
  //let rows = [];

  const [listLanr, setLanr] = useState([]);

  const [rows, setRows] = useState([]);

  const [totalRowsCount, setTotalRowsCount] = useState(0);

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [loading, setLoading] = useState(false); // State to manage loading indication

  const [search, setSearch] = React.useState("");
  
  //const getLanrData =  async () => { setLanr(await DatabaseService.getLanr()); }

  const getLanrData = async (page, rowsPerPage, search) => {
      try {
          const lanrData = await DatabaseService.getLanr((page + 1), rowsPerPage, search);

          if (lanrData && lanrData.rows) {
              setLanr(lanrData.rows);
          }

          if (lanrData && lanrData.totalCount) {
              setTotalRowsCount(lanrData.totalCount);
          }
          //setGemeinschaftspraxis(await DatabaseService.getGemeinschaftspraxis());
      } catch (error) {
          console.error('Error fetching lanr data:', error);
      }
  }

  useEffect(() => {

        setLoading(true); // Set loading to true when fetching data

        getLanrData(page, rowsPerPage, search)
          .then(() => setLoading(false))
          .catch(error => {
              console.log('Error fetching set lanr data:', error);
              setLoading(false);
          });
          
  }, [page, rowsPerPage, search]);

  useEffect(() => {

      // Map listAllUsers to create rows and update the state
      const updatedRows = listLanr.map(item => createData(
          item.id,
          item.lg,
          item.gop_snr,
          item.art,
          item.haeufigkeit_gop,
          item.wertigkeit,
          item.wertigkeit_nach,
          item.gesamtvolumen
      ));

      // Update the rows state
      setRows(updatedRows);

  }, [listLanr]);
  
  //const getDelPDFFile =  async () => { setDelPDFFile(await DatabaseService.delPDFFile(data)); }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  
  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { 
    id: 'lg', 
    label: 'LG',  
  },
  {
    id: 'gop_snr',
    label: 'GOP',
  },
  {
    id: 'art',
    label: 'Art',
  },
  {
    id: 'haeufigkeit_gop',
    label: 'Haeufigkeit GOP',
  },
  {
    id: 'wertigkeit',
    label: 'Wertigkeit',
  },
  {
    id: 'wertigkeit_nach',
    label: 'Wertigkeit Nach',
  },
  {
    id: 'gesamtvolumen',
    label: 'Gesamtvolumen',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
        paddingRight: '16px!important', 
        display: { xs: 'block', sm: 'flex' },
        paddingTop: { xs: '8px', sm: '0' },
        paddingBottom: { xs: '14px', sm: '0' },
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          
          Lanr

        </Typography>
      )}


      {
        
      numSelected > 0 ? (<>
        <Tooltip title="Delete">
                      <Button color="error" variant="contained" onClick={onDelete}>
                          <DeleteIcon />
                      </Button>
        </Tooltip>
        </>
      ) : (
           <>
              <SearchInput setSearch={setSearch} setPage={setPage} search={search} />
                          {/*<Tooltip title="Filter list">
                              <IconButton>
                                  <FilterListIcon />
                              </IconButton>
                          </Tooltip>*/}
           </> 
      )
      
      }
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

/*Dialog Box*/
const LoadingContainer = styled('div')(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }),
);

const [open, setOpen] = React.useState(false);
const [dialogInformation, setDialogInformation] = React.useState(false);
const theme = useTheme();
const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
/*Dialog Box*/

const [order, setOrder] = React.useState('asc');
const [orderBy, setOrderBy] = React.useState('calories');
const [selected, setSelected] = React.useState([]);
const [dense, setDense] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    
  const visibleRows = stableSort(rows, getComparator(order, orderBy));
  
  function delLanr_func(data){
    return DatabaseService.delLanr(data)
  }

  const onDelete = (event) => {
    
    setOpen(true);
    setDialogInformation("Deleting...");

    delLanr_func(selected)
          .then(response => {
              if (response.ok) {
                  return response.json();
              } else {
                  throw new Error('Failed to delete lanr');
              }
          })
          .then(result => {
              console.log(result);
              setOpen(false);
              // Update table data after successful deletion

              getLanrData(page, rowsPerPage, search);
              setSelected([]); // Clear selected array
          })
          .catch(error => {
              console.error('Error deleting lanr:', error);
              setOpen(false);
              // Handle error here, show message or take appropriate action
          });
  };

   /* Dialog Box */
   /*const handleClickOpen = () => {
     setOpen(true);
   };*/

   const handleClose = () => {
       //setOpen(false);
   };
   /* Dialog Box */

//Table

  //=========== Upload Files

  //==== Upload Files

    const LoadingTable = styled('div')(({ theme }) =>
        theme.unstable_sx({
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.50)',
            zIndex: 1,
            paddingTop: '2.7em'
        }),
    );
  
  return (
            <>
                  <br></br>
                  <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                      <EnhancedTableToolbar numSelected={selected.length} />
                        <TableContainer sx={{ position: 'relative' }}>

                          {
                              loading ? (

                                  <LoadingTable>
                                      <div>
                                          <CircularProgress />
                                      </div>
                                  </LoadingTable>
                              ) : ""
                          }

                            <Table
                              sx={{ minWidth: 750 }}
                              aria-labelledby="tableTitle"
                              size={dense ? 'small' : 'medium'}
                            >
                              <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                              />
                              <TableBody>
                                  {
                                      (visibleRows.length > 0) ?
                                          visibleRows.map((row, index) => {
                                          const isItemSelected = isSelected(row.id);
                                          const labelId = `enhanced-table-checkbox-${index}`;

                                              return (
                                                <TableRow
                                                  hover
                                                  onClick={(event) => handleClick(event, row.id)}
                                                  role="checkbox"
                                                  aria-checked={isItemSelected}
                                                  tabIndex={-1}
                                                  key={row.id}
                                                  selected={isItemSelected}
                                                  sx={{ cursor: 'pointer' }}
                                                  className='checkboxId'
                                                  data-id={row.id}
                                                >
                                                  <TableCell padding="checkbox">
                                                    <Checkbox
                                                      color="primary"
                                                      checked={isItemSelected}
                                                      inputProps={{
                                                        'aria-labelledby': labelId,
                                                      }}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                  >
                                                    {row.lg}
                                                  </TableCell>
                                                  <TableCell>{row.gop_snr}</TableCell>
                                                  <TableCell>{row.art}</TableCell>
                                                  <TableCell>{row.haeufigkeit_gop}</TableCell>
                                                  <TableCell>{row.wertigkeit}</TableCell>
                                                  <TableCell>{row.wertigkeit_nach}</TableCell>
                                                  <TableCell>{row.gesamtvolumen}</TableCell>
                                                </TableRow>
                                              );
                                          })
                                       : loading ? (
                                              <TableRow
                                                  style={{
                                                      height: '120px',
                                                  }}
                                              ><TableCell colSpan={6} />
                                              </TableRow>
                                       ) : null
                                  }
                              </TableBody>
                            </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={parseInt(totalRowsCount)}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </Paper>
                    <FormControlLabel
                      control={<Switch checked={dense} onChange={handleChangeDense} />}
                      label="Dense padding"
                    />
                  </Box>

                  <Dialog
                      fullScreen={fullScreen}
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                  >
                      {/*<DialogTitle id="responsive-dialog-title">
                                  "Use Google's location service?"
                              </DialogTitle>*/}
                      <DialogContent>
                          {/*<DialogContentText>*/}
                          {/*Let Google help apps determine location. This means sending anonymous
                                      location data to Google, even when no apps are running.*/}

                          <LoadingContainer>
                              <CircularProgress sx={{ mr: 2 }} /> {dialogInformation}
                          </LoadingContainer>

                          {/*</DialogContentText>*/}
                      </DialogContent>
                      {/*<DialogActions>
                                  <Button autoFocus onClick={handleClose}>
                                      Disagree
                                  </Button>
                                  <Button onClick={handleClose} autoFocus>
                                      Agree
                                  </Button>
                              </DialogActions>*/}
                  </Dialog>
            </>
          );
}

/*export default function MainPage1() {

  const classes = useStyles();

  const [listCountAllApplications, setCountAllApplications] = useState([])

  const [listMonthlyTotalApplications, setMonthlyTotalApplications] = useState([]);

  const getDataCountAllApplications = async () => { setCountAllApplications(await DatabaseService.getData("applications_countall")); }

  const getDataMonthlyTotalApplications = async () => { setMonthlyTotalApplications(await DatabaseService.getData("monthly_totalapplications")); }

  useEffect(() => {
    getDataCountAllApplications();
    getDataMonthlyTotalApplications();

  }, []);

  const barChart = processChartData(listMonthlyTotalApplications, 'bar');

  function CategoryApplicationCounter() {
    return (
      <>
          { 
              listCountAllApplications.map((item, index) => (
                  <Grid item xs={12} md={6} lg={3} key={item.id}>
                          <CategoryCounter  
                              urlID={item.id} 
                              totalCount={item.count}
                              year={item.yr}
                              title={item.name}   
                              bgColorID={index}
                          />
                  </Grid>
              ))
          }
      </>
    );
  }

  //Chart
  function CategoryCounterChart(){
    return (
      <> 
        <Grid item xs={12}>      
          <BarChart 
            height="30vw"
            width="100%"
            labels={barChart.labels}
            datasets={barChart.datasets}
            headerTitle="Monthly Total Applications in 2021" 
            classNameCanvasSize={classes.canvasSize} 
          />               
        </Grid>
      </>
    );
  }

  return (

    <MuiDrawer>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid container item spacing={3} className={classes.gridApplicationCounter}>
            <CategoryApplicationCounter />
          </Grid>
          
          <Grid container item spacing={3} className={classes.gridCategoryCounterChart}>
              <CategoryCounterChart />
          </Grid>
        </Grid>
      </div>
    </MuiDrawer>
    
  );
}*/