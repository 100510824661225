import React, { useState, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';

const SearchDiv = styled('div')(({ theme }) =>
    theme.unstable_sx({
        display: 'inline-flex',
        position: 'relative',
        '& .MuiInputBase-input': {
            padding: '6.5px 14px',
            paddingRight: '41px',
        },
        '& .MuiInputBase-root': {
            borderColor: 'rgba(25, 118, 210, 0.5)'
        },
        '& .MuiButtonBase-root': {
            position: 'absolute',
            right: 0,
            minWidth: '42px',
            border: '0!important',
            padding: '6px 0px',
            borderRadius: '0 4px 4px 0'
        },
        label: {
            top: "-3px",
            '&[data-shrink="true"]': {
                // Add your styles here for the label when data-shrink is true
                top: 0,
            },
        },
    }),
);

const SearchInput = ({ setSearch, setPage, search }) => {
    const [searchTerm, setSearchTerm] = useState(search);

    // useCallback to memoize the handleChange function
    const handleChange = useCallback((event) => {
        setSearchTerm(event.target.value);
    }, []);

    // useCallback to memoize the handleSearch function
    const handleSearch = useCallback((e) => {
        e.preventDefault();
        // Perform search logic here

        setSearch(searchTerm);
        setPage(0);
    }, [searchTerm, setSearch, setPage]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            setSearch(searchTerm);
            setPage(0);
        }
    };

    return (
        <>
            <SearchDiv>
                <div className='col-1'>
                    <TextField
                        id="search"
                        label="Search"
                        name="search"
                        fullWidth
                        type="text"
                        size="small"
                        value={searchTerm}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress} // Trigger search on Enter key press
                    />
                </div>
                <div className='col-2'>
                    <Button variant="outlined" onClick={handleSearch}>
                        <SearchIcon />
                    </Button>
                </div>
            </SearchDiv>
        </>
    );
};

export default SearchInput;