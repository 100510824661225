import React, { useEffect, useState, createRef } from 'react';
//import { makeStyles } from '@material-ui/core/styles';
//import Grid from '@material-ui/core/Grid';
import Grid from '@mui/material';
import * as DatabaseService from '../../services/DatabaseService';
//import CategoryCounter from '../../components/CategoryCounter';
//import BarChart from '../../components/charts/BarChart';
//import processChartData from '../../components/charts/processChartData';

import TablePaginationActions from '../../components/TablePaginationActions'; // Adjust the path accordingly
import SearchInput from '../../components/SearchInput';

// Table Libraries
import PropTypes from 'prop-types';
import { alpha, useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

/* Dialog Box */
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
/* End of Dialog Box */

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StorageIcon from '@mui/icons-material/Storage';

import Button from '@mui/material/Button';
import { display } from '@mui/system';
// Table Libraries

export default function ExtractorPage() {

  function createData(  id,
                        gop,
                        haeufigkeit_praxis,
                        wert_gop_punkte_eur,
                        gesamtbetrag_gop_in_punkten_eur,
                        ansatz_in_faellen,
                        haeufigkeit_je_fall_praxis,
                        ansatz_auf_faelle_praxis,
                        ansatz_auf_faelle_pg,
                        haeufigkeit_faelle_praxis,
                        haeufigkeit_faelle_pg,
                        pkt_eur_je_fall,
                        pkt_eur_je_fall_pg,
                        abweichung_der_praxis_in_percent,
                        ansatz_bei_praxen_pg_in_percent) {
    return {
            id,
            gop,
            haeufigkeit_praxis,
            wert_gop_punkte_eur,
            gesamtbetrag_gop_in_punkten_eur,
            ansatz_in_faellen,
            haeufigkeit_je_fall_praxis,
            ansatz_auf_faelle_praxis,
            ansatz_auf_faelle_pg,
            haeufigkeit_faelle_praxis,
            haeufigkeit_faelle_pg,
            pkt_eur_je_fall,
            pkt_eur_je_fall_pg,
            abweichung_der_praxis_in_percent,
            ansatz_bei_praxen_pg_in_percent
    };
  }

  /*let rows = [
                createData('1','01/00','01100','GRÃœN','5','5','0,29','0,31','196,0','196,0','980,0',''),
                createData('2','01/00','01102','ROT','1','1','0,06','0,05','101,0','101,0','101,0',''),
                createData('3','01/00','08220','ROSAROT','1.430','1.430','81,53','66,47','24,0','24,0','34.320,0','')
             ];*/
  
  //let rows = [];

  const [listHonorarunterlagen, setHonorarunterlagen] = useState([]);

  const [rows, setRows] = useState([]);

  const [totalRowsCount, setTotalRowsCount] = useState(0);

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [loading, setLoading] = useState(false); // State to manage loading indication

  const [search, setSearch] = React.useState("");

  //const getPDFFilesData =  async () => { setPDFFiles(await DatabaseService.getPDFFilesData()); }

  //const getHonorarunterlagenData =  async () => { setHonorarunterlagen(await DatabaseService.getHonorarunterlagen()); }

  const getHonorarunterlagenData = async (page, rowsPerPage, search) => {

      try {
          const honorarunterlagenData = await DatabaseService.getHonorarunterlagen((page + 1), rowsPerPage, search);

          if (honorarunterlagenData && honorarunterlagenData.rows) {
              setHonorarunterlagen(honorarunterlagenData.rows);
          }

          if (honorarunterlagenData && honorarunterlagenData.totalCount) {
              setTotalRowsCount(honorarunterlagenData.totalCount);
          }

      } catch (error) {
          console.error('Error fetching lanr data:', error);
      }

  }

  useEffect(() => {
      //getPDFFilesData();
      //getHonorarunterlagenData();

      setLoading(true); // Set loading to true when fetching data

      getHonorarunterlagenData(page, rowsPerPage, search)
          .then(() => setLoading(false))
          .catch(error => {
              console.log('Error fetching set honorarunterlagen data:', error);
              setLoading(false);
          });

  }, [page, rowsPerPage, search]);

    useEffect(() => {

        // Map listAllUsers to create rows and update the state
        const updatedRows = listHonorarunterlagen.map(item => createData(
            item.id,
            item.gop,
            item.haeufigkeit_praxis,
            item.wert_gop_punkte_eur,
            item.gesamtbetrag_gop_in_punkten_eur,
            item.ansatz_in_faellen,
            item.haeufigkeit_je_fall_praxis,
            item.ansatz_auf_faelle_praxis,
            item.ansatz_auf_faelle_pg,
            item.haeufigkeit_faelle_praxis,
            item.haeufigkeit_faelle_pg,
            item.pkt_eur_je_fall,
            item.pkt_eur_je_fall_pg,
            item.abweichung_der_praxis_in_percent,
            item.ansatz_bei_praxen_pg_in_percent
        ));

        // Update the rows state
        setRows(updatedRows);

    }, [listHonorarunterlagen])
    
  //const getDelPDFFile =  async () => { setDelPDFFile(await DatabaseService.delPDFFile(data)); }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  
  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'gop',
    label: '1 GOP',
    disablePadding: true,
  },
  {
    id: 'haeufigkeit_praxis',
    label: '2 Haeufigkeit Praxis',
    disablePadding: true,
  },
  {
    id: 'wert_gop_punkte_eur',
    label: '3 Wert GOP Punkte Eur',
    disablePadding: true,
  },
  {
    id: 'gesamtbetrag_gop_in_punkten_eur',
    label: '4 Gesamtbetrag GOP In Punkten Eur',
    disablePadding: true,
  },
  {
    id: 'ansatz_in_faellen',
    label: '5 Ansatz In Faellen',
    disablePadding: true,
  },
  {
    id: 'haeufigkeit_je_fall_praxis',
    label: '6 Haeufigkeit Je Fall Praxis',
    disablePadding: true,
  },
  {
    id: 'ansatz_auf_faelle_praxis',
    label: '7 Ansatz Auf Faelle Praxis',
    disablePadding: true,
  },
  {
    id: 'ansatz_auf_faelle_pg',
    label: '8 Ansatz Auf Faelle Pg',
    disablePadding: true,
  },
  {
    id: 'haeufigkeit_faelle_praxis',
    label: '9 Haeufigkeit Faelle Praxis',
    disablePadding: true,
  },
  {
    id: 'haeufigkeit_faelle_pg',
    label: '10 Haeufigkeit Faelle Pg',
    disablePadding: true,
  },
  {
    id: 'pkt_eur_je_fall',
    label: '11 Pkt Eur Je Fall',
    disablePadding: true,
  },
  {
    id: 'pkt_eur_je_fall_pg',
    label: '12 Pkt Eur Je fall Pg',
    disablePadding: true,
  },
  {
    id: 'abweichung_der_praxis_in_percent',
    label: '13 Abweichung Der Praxis In Percent',
    disablePadding: true,
  },
  {
    id: 'ansatz_bei_praxen_pg_in_percent',
    label: '14 Ansatz Bei Praxen PG In Percent',
    disablePadding: true,
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
        display: { xs: 'block', sm: 'flex' },
        paddingTop: { xs: '8px', sm: '0' },
        paddingBottom: { xs: '14px', sm: '0' },
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          
          Honorarunterlagen

        </Typography>
      )}


      {
        
      numSelected > 0 ? (<>
        <Tooltip title="Delete">
          <Button color="error" variant="contained" onClick={onDelete}>
              <DeleteIcon />
          </Button>
        </Tooltip>
        </>
      ) : (
            <>
               <SearchInput setSearch={setSearch} setPage={setPage} search={search} />
               {/*<Tooltip title="Filter list">
                              <IconButton>
                                  <FilterListIcon />
                              </IconButton>
                          </Tooltip>*/}
            </>
      )
      
      }
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

/*Dialog Box*/
const LoadingContainer = styled('div')(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }),
);

const [open, setOpen] = React.useState(false);
const [dialogInformation, setDialogInformation] = React.useState(false);
const theme = useTheme();
const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
/*Dialog Box*/

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  /*const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );*/

  /*const visibleRows = stableSort(rows, getComparator(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );*/

  const visibleRows = stableSort(rows, getComparator(order, orderBy));
  
  function delHonorarunterlagen_func(data){
    return DatabaseService.delHonorarunterlagen(data)
  }

  const onDelete = (event) => {

    setOpen(true);
    setDialogInformation("Deleting...");

      delHonorarunterlagen_func(selected)
          .then(response => {
              if (response.ok) {
                  return response.json();
              } else {
                  throw new Error('Failed to delete lanr');
              }
          })
          .then(result => {
              console.log(result);
              setOpen(false);
              // Update table data after successful deletion

              getHonorarunterlagenData(page, rowsPerPage, search);
              setSelected([]); // Clear selected array
          })
          .catch(error => {
              console.error('Error deleting lanr:', error);
              setOpen(false);
              // Handle error here, show message or take appropriate action
          });

  };

  /* Dialog Box */
  /*const handleClickOpen = () => {
    setOpen(true);
  };*/

  const handleClose = () => {
      //setOpen(false);
  };
  /* Dialog Box */

//Table

  //=========== Upload Files

  //==== Upload Files

    const LoadingTable = styled('div')(({ theme }) =>
        theme.unstable_sx({
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.50)',
            zIndex: 1,
            paddingTop: '2.7em'
        }),
    );
  
  return (
            <>
                  <br></br>

                  <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                      <EnhancedTableToolbar numSelected={selected.length} />
                        <TableContainer sx={{ position: 'relative' }}>

                          {
                              loading ? (

                                  <LoadingTable>
                                      <div>
                                          <CircularProgress />
                                      </div>
                                  </LoadingTable>
                              ) : ""
                          }


                        <Table
                          sx={{ minWidth: 750 }}
                          aria-labelledby="tableTitle"
                          size={dense ? 'small' : 'medium'}
                        >
                          <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                          />
                          <TableBody>
                              {
                                  (visibleRows.length > 0) ?
                                        visibleRows.map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                          return (
                                            <TableRow
                                              hover
                                              onClick={(event) => handleClick(event, row.id)}
                                              role="checkbox"
                                              aria-checked={isItemSelected}
                                              tabIndex={-1}
                                              key={row.id}
                                              selected={isItemSelected}
                                              sx={{ cursor: 'pointer' }}
                                              className='checkboxId'
                                              data-id={row.id}
                                            >
                                              <TableCell padding="checkbox">
                                                <Checkbox
                                                  color="primary"
                                                  checked={isItemSelected}
                                                  inputProps={{
                                                    'aria-labelledby': labelId,
                                                  }}
                                                />
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                              >
                                                {row.gop}
                                              </TableCell>
                                              <TableCell>{row.haeufigkeit_praxis}</TableCell>
                                              <TableCell>{row.wert_gop_punkte_eur}</TableCell>
                                              <TableCell>{row.gesamtbetrag_gop_in_punkten_eur}</TableCell>
                                              <TableCell>{row.ansatz_in_faellen}</TableCell>
                                              <TableCell>{row.haeufigkeit_je_fall_praxis}</TableCell>
                                              <TableCell>{row.ansatz_auf_faelle_praxis}</TableCell>
                                              <TableCell>{row.ansatz_auf_faelle_pg}</TableCell>
                                              <TableCell>{row.haeufigkeit_faelle_praxis}</TableCell>
                                              <TableCell>{row.haeufigkeit_faelle_pg}</TableCell>
                                              <TableCell>{row.pkt_eur_je_fall}</TableCell>
                                              <TableCell>{row.pkt_eur_je_fall_pg}</TableCell>
                                              <TableCell>{row.abweichung_der_praxis_in_percent}</TableCell>
                                              <TableCell>{row.ansatz_bei_praxen_pg_in_percent}</TableCell>
                                            </TableRow>
                                          );
                                        })
                                      : loading ? (
                                          <TableRow
                                              style={{
                                                  height: '120px',
                                              }}
                                          ><TableCell colSpan={6} />
                                          </TableRow>
                                      ) : null
                              }
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={parseInt(totalRowsCount)}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </Paper>
                    <FormControlLabel
                      control={<Switch checked={dense} onChange={handleChangeDense} />}
                      label="Dense padding"
                    />
                  </Box>

                  <Dialog
                      fullScreen={fullScreen}
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                  >
                      {/*<DialogTitle id="responsive-dialog-title">
                                  "Use Google's location service?"
                              </DialogTitle>*/}
                      <DialogContent>
                          {/*<DialogContentText>*/}
                          {/*Let Google help apps determine location. This means sending anonymous
                                      location data to Google, even when no apps are running.*/}

                          <LoadingContainer>
                              <CircularProgress sx={{ mr: 2 }} /> {dialogInformation}
                          </LoadingContainer>

                          {/*</DialogContentText>*/}
                      </DialogContent>
                      {/*<DialogActions>
                                  <Button autoFocus onClick={handleClose}>
                                      Disagree
                                  </Button>
                                  <Button onClick={handleClose} autoFocus>
                                      Agree
                                  </Button>
                              </DialogActions>*/}
                  </Dialog>
            </>
          );
}

/*export default function MainPage1() {

  const classes = useStyles();

  const [listCountAllApplications, setCountAllApplications] = useState([])

  const [listMonthlyTotalApplications, setMonthlyTotalApplications] = useState([]);

  const getDataCountAllApplications = async () => { setCountAllApplications(await DatabaseService.getData("applications_countall")); }

  const getDataMonthlyTotalApplications = async () => { setMonthlyTotalApplications(await DatabaseService.getData("monthly_totalapplications")); }

  useEffect(() => {
    getDataCountAllApplications();
    getDataMonthlyTotalApplications();

  }, []);

  const barChart = processChartData(listMonthlyTotalApplications, 'bar');

  function CategoryApplicationCounter() {
    return (
      <>
          { 
              listCountAllApplications.map((item, index) => (
                  <Grid item xs={12} md={6} lg={3} key={item.id}>
                          <CategoryCounter  
                              urlID={item.id} 
                              totalCount={item.count}
                              year={item.yr}
                              title={item.name}   
                              bgColorID={index}
                          />
                  </Grid>
              ))
          }
      </>
    );
  }

  //Chart
  function CategoryCounterChart(){
    return (
      <> 
        <Grid item xs={12}>      
          <BarChart 
            height="30vw"
            width="100%"
            labels={barChart.labels}
            datasets={barChart.datasets}
            headerTitle="Monthly Total Applications in 2021" 
            classNameCanvasSize={classes.canvasSize} 
          />               
        </Grid>
      </>
    );
  }

  return (

    <MuiDrawer>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid container item spacing={3} className={classes.gridApplicationCounter}>
            <CategoryApplicationCounter />
          </Grid>
          
          <Grid container item spacing={3} className={classes.gridCategoryCounterChart}>
              <CategoryCounterChart />
          </Grid>
        </Grid>
      </div>
    </MuiDrawer>
    
  );
}*/