import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import DBTable1 from './DBTable1';
import DBTable2 from './DBTable2';
import DBTable3 from './DBTable3';

export default function LabTabs() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{ '& .MuiTabs-scroller': { overflowX: 'auto!important' } }}>
            <Tab label="Gemeinschaftspraxis" value="1" />
            <Tab label="Lanr" value="2" />
            <Tab label="Honorarunterlagen" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ p: 0 }}><DBTable1 /></TabPanel>
        <TabPanel value="2" sx={{ p: 0 }}><DBTable2 /></TabPanel>
        <TabPanel value="3" sx={{ p: 0 }}><DBTable3 /></TabPanel>
      </TabContext>
    </Box>
  );
}
