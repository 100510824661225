import React, { useState, useEffect, createRef, useRef } from 'react';
import {NavLink, useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import { List, ListItem, ListItemText, ListItemButton, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import useMediaQuery from '@mui/material/useMediaQuery';

/*Dialog*/
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SearchInput from './SearchInput';
/*End of Dialog*/

import SearchIcon from '@mui/icons-material/Search';

import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircle from '@mui/icons-material/AccountCircle';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import MenuIcon from '@mui/icons-material/Menu';

import { jsx, css, Global, ClassNames } from '@emotion/react';

import { useAuth } from '../contexts/AuthContext';
import { minWidth, styled, width } from '@mui/system';

import LoadingSpecificComponent from './LoadingSpecificComponent';
//import DatabaseService from '../services/DatabaseService';

import * as DatabaseService from '../services/DatabaseService';
import { useEnvData } from '../services/envService';
import { ConstructionOutlined, ElevatorSharp } from '@mui/icons-material';

import { createTheme, responsiveFontSizes } from '@mui/material';
import { ThemeProvider } from '@mui/material';

import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import FloatingIcons from '../components/FloatingIcons';

import Logo from '../components/assets/images/gynsuccess-logo.webp'; 

import NestedMenu from './NestedMenu';

const drawerWidth = 240;

const SearchDiv1 = styled('div')(({ theme }) =>
    theme.unstable_sx({
        '& .css-nzfrq3':{
          width: '100%',
        },
        '& .col-1':{
          width: '100%',
        }
    }),
);

const SearchResultsDiv = styled('div')(({ theme }) =>
    theme.unstable_sx({
        '& .css-o69ov4': {
          position: 'relative',
          paddingTop: '1em'
        },
        '& a':{
          paddingBottom: '7px',
          display: 'block'
        }
    }),
);


const SearchEngineComponent = () => {
  
  const [listAllSearch, setAllSearchData] = useState([]);

  const [totalRowsCount, setTotalRowsCount] = useState(0);

  const [search, setSearch] = React.useState('');
  
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
  const [loading, setLoading] = useState(false); // State to manage loading indication

  const getSearchResult = async (page, rowsPerPage, search) => {
    try {

        const searchEngineData = await DatabaseService.getSearchEngine(page, rowsPerPage, search);

        if (searchEngineData && searchEngineData.rows) {
            setAllSearchData(searchEngineData.rows); // Set the user data array to listAllPostCategories
        }

        if (searchEngineData && searchEngineData.totalCount) {
            setTotalRowsCount(searchEngineData.totalCount); // Set the user data array to listAllPostCategories
            //setRowsPerPage(postCategoryData.totalCount); // Set the user data array to listAllPostCategories
        } else {
            setTotalRowsCount(0)
        }

    } catch (error) {
        console.error('Error fetching search result data:', error);
    }
  }

  useEffect(() => {
    setLoading(true); // Set loading to true when fetching data
    getSearchResult(page, rowsPerPage, search)
        .then(() => setLoading(false))
        .catch(error => {
            console.log('Error fetching search result data:', error);
            setLoading(false);
        });

  }, [page, rowsPerPage, search]);

  return (<>

    <SearchDiv1>  
      <SearchInput setSearch={setSearch} setPage={setPage} search={search} />
    </SearchDiv1>
    <SearchResultsDiv>
    {
      (search!== "") ? <><LoadingSpecificComponent loading={loading} /></> : ""
    }
    {
      (search!== "") ? (listAllSearch.length > 0) ? <><br /></> : "" : ""
    }
    { 
      (search!== "") ?
        listAllSearch.map((result) => {
          return (<><Typography component={NavLink} to={`/article/${result.id}`} variant="p"> {result.title} </Typography></>)
        })
      : ""
    }
    </SearchResultsDiv>
  </>);
}

export default function DrawerStyle({ children }) {

    const { logout, currentUser, getSession, currentRoleId } = useAuth();

    const { isDevMode } = useEnvData();

    const roleId = parseInt(currentRoleId); // Parses currentRoleId as an integer

    const [openDialog, setOpenDialog] = React.useState(false);

    const navigate = useNavigate();
    
    const [anchorEl, setAnchorEl] = React.useState(null);

    const isMobileOrTablet = useMediaQuery(theme => theme.breakpoints.down('md'));

    const [openSidebar, setOpenSidebar] = React.useState( isMobileOrTablet ? false : (localStorage.getItem('drawerOpen') === 'false' || true));

    const location = useLocation();

    let theme = createTheme({
      typography: {
        fontFamily: [
          'BR Firma Medium',
          'Roboto',
          'Arial',
          'sans-serif',
        ].join(','),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: '20px',
            },
          },
        },
      },
    });

	  theme = responsiveFontSizes(theme);

    const isPathActive = (path) => {
      return location.pathname + location.search === path;
    };

    /*useEffect(() => {
      console.log("isPathActive('/post-new')")
      console.log(isPathActive('/post-new'))
      console.log("location.pathname")
      console.log(location.pathname)
      console.log("location")
      console.log(location)
    })*/

      /*useEffect(() => {
        console.log("roleId")
        console.log(roleId)
      })*/
      
    const handleMenuLogout = async () => {
        await logout();
    }

    const isMenuOpen = Boolean(anchorEl);
    const menuId = 'primary-search-account-menu';
    
    const handleSidebarOpenClose = () => {
        setOpenSidebar(!openSidebar);
        localStorage.setItem('drawerOpen', !openSidebar);
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
        //handleMobileMenuClose();
      };

      const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
      };

    const renderMenu = (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={menuId}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem disabled>User: { currentUser }</MenuItem>
          <MenuItem onClick={handleMenuLogout}>Logout</MenuItem>
        </Menu>
      );
      
    /*DIALOG*/
    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false);
    };
    /*END OF DIALOG*/

  //Roles: 1 - Admin, 2 - Testmitglied, 4 - Superadmin, 5 - Vollmitglied

  const menuItems = [
    {
      key: 'plattform',
      label: 'Plattform',
      path: '/plattform',
      subMenu: [
        {
          key: 'abrechnungskompass',
          label: 'Abrechnungskompass',
          path: '/plattform/abrechnungskompass',
          subMenu: [
            {
              key: 'ebm_abrechnungskompass',
              label: 'EBM',
              path: '/ebm-abrechnungskompass',
              subMenu: [
                {
                  key: 'abrechnungskompass_csv',
                  label: 'CSV-Dateien',
                  path: '/ebm-abrechnungskompass/csv',
                  hide: roleId !== 1 && roleId !== 4,
                },
              ]
            },
            {
              key: 'ebm_abrechnungskompass-fix',
              label: 'EBM Fix',
              path: '/ebm-abrechnungskompass-fix',
              //hide: !isDevMode || (roleId !== 1 && roleId !== 4),
              hide: true,
              subMenu: []
            },
            {
              key: 'goa_abrechnungskompass',
              label: 'GOÄ',
              path: '/gebuehrenordnung-fuer-aerzte-abrechnungskompass',
              hide: !isDevMode || (roleId !== 1 && roleId !== 4),
              subMenu: [
                {
                  key: 'fachgruppenvergleich',
                  label: 'Fachgruppenvergleich',
                  path: '/gebuehrenordnung-fuer-aerzte-abrechnungskompass/fachgruppenvergleich',
                  subMenu: [],
                },
                {
                  key: 'abrechnungskasuistiken',
                  label: 'Kasuistiken',
                  path: '/gebuehrenordnung-fuer-aerzte-abrechnungskompass/abrechnungskasuistiken',
                  subMenu: [],
                },
              ],
            },
          ],
        },
        {
          key: 'coaching_beratung',
          label: 'Coaching & Beratung',
          path: '/plattform/coaching-praxisberatung',
          subMenu: [],
        },
        {
          key: 'digitalisierungssupport',
          label: 'Digitalisierungssupport',
          path: '/plattform/digitalisierungssupport',
          subMenu: [],
        },
        {
          key: 'workshops',
          label: 'Workshops',
          path: '/plattform/workshop',
          subMenu: [],
        },
      ],
    },
    {
      key: 'mediathek',
      label: 'Mediathek',
      path: '/mediathek',
    },
    {
      key: 'ebm_koeffizienten',
      label: 'EBM Koeffizienten',
      path: '/ebm-koeffizienten',
      hide: true,
    },
    {
      key: 'compare',
      label: 'Compare',
      path: '/compare',
      hide: true,
    },
    {
      key: 'ebm_kunde',
      label: 'EBM Kunde',
      path: '/ebm-kunde',
      hide: true,
    },
    {
      key: 'ebm_berechnungsdaten',
      label: 'EBM Berechnungsdaten',
      path: '/ebm-berechnungsdaten',
      hide: true,
    },
    {
      key: 'pdftodocx',
      label: 'PDF to DOCX',
      path: '/pdftodocx',
      hide: roleId !== 1 && roleId !== 4,
    },
    {
      key: 'pdftocsv',
      label: 'PDF to CSV',
      path: '/pdftocsv',
      hide: roleId !== 1 && roleId !== 4,
    },
    {
      key: 'contocsv',
      label: 'CON to CSV',
      path: '/contocsv',
      hide: true,
    },
    {
      key: 'dokumentenupload',
      label: 'Dokumentenupload',
      path: '/dokumentenupload',
    },
    {
      key: 'dateimanager',
      label: 'Dateimanager',
      path: '/dateimanager',
      hide: roleId !== 1 && roleId !== 4,
    },
    {
      key: 'users',
      label: 'Users',
      path: '/users',
      hide: roleId !== 1 && roleId !== 4,
    },
    {
      key: 'profil',
      label: 'Profil',
      path: '/profil',
    },
    {
      key: 'articles',
      label: 'Articles',
      path: '/articles',
      hide: true,
    },
    {
      key: 'podcasts',
      label: 'Podcasts',
      path: '/podcasts',
      hide: true,
    },
    {
      key: 'videos',
      label: 'Videos',
      path: '/videos',
      hide: true,
    },
    {
      key: 'post_new',
      label: 'Add New Post',
      path: '/post-new',
      hide: true,
    },
    {
      key: 'add_podcast',
      label: 'Add New Podcast',
      path: '/post-new?post_type=2',
      hide: true,
    },
    {
      key: 'add_video',
      label: 'Add New Video',
      path: '/post-new?post_type=3',
      hide: true,
    },
  ];

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{'& .MuiPaper-root': {width: '100%'}}}
      >
          <DialogContent> 
            <DialogContentText id="alert-dialog-description">
              <SearchEngineComponent />
            </DialogContentText>
          </DialogContent>
      </Dialog>

    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'white' }}>
        <Toolbar>
          <Box sx={{left: '-13px', position:'relative'}}>
            <IconButton
                      size="large"
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={handleSidebarOpenClose}
                   
                  >
                <MenuIcon />
              </IconButton>
          </Box>
          <Box>
          <Typography variant="h6" noWrap component="div" color={'black'} sx={{display:'flex'}}>
            {/*Clipped drawer*/}
            <img src={Logo} width={'148px'} />
          </Typography>
          </Box>
          <Box sx={{ flexGrow: 1}} />
          <Box>
            <IconButton
                      size="large"
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={handleClickOpenDialog}
               
                  >
                <SearchIcon />
              </IconButton>
          </Box>
          <Box>
               <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
             
                >
              <AccountCircle />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
      <Drawer
        variant={isMobileOrTablet ? 'temporary' : 'persistent'}
        open={openSidebar}
        sx={{
          width: openSidebar ? drawerWidth : 0,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto'}}>
             <List sx={{ paddingTop: '19px', paddingBottom: '8px', paddingLeft: '11px', paddingRight: '11px', fontSize: '15px' }}>
               <NestedMenu menu={menuItems} />
            </List> 
        </Box>
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 3, width:0 }}>
        <Toolbar />
        <ThemeProvider theme={theme}>
          { children }
          <FloatingIcons />
        </ThemeProvider>
      </Box>      
    </Box>
    </>
  );
}
