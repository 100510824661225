import React, { useEffect, useState, createRef, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import * as DatabaseService from '../../services/DatabaseService';
import MuiDrawer from '../../components/MuiDrawer';
// Table Libraries
import { alpha, useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';

import DialogOutput from '../../components/DialogOutput';
import ViewFormOutput from '../../components/ViewFormOuput';

import Button from '@mui/material/Button';
// Table Libraries

import TextField from '@mui/material/TextField';

import Link from '@mui/material/Link';

import germanFormatCurrency from '../../utils/germanFormatCurrency';
import percentToDecimal from '../../utils/percentToDecimal';

import ViewFormOutputStyle from './styles/ViewFormOutputStyle'

//Calculations
import countGesamtpotenzialIncluded from './calculations/countGesamtpotenzialIncluded';
import countIhrPotencialIncluded from './calculations/countIhrPotencialIncluded';
import checkDeviationPercentStatus from './calculations/checkDeviationPercentStatus';
import checkDeviationPercentColor from './calculations/checkDeviationPercentColor';
//End of Calculations

import filterAndSortInputRows from './utils/sortFilteredInputRows';
import filterAndSortRows from './utils/sortFilteredRows';

import PieChartFilter from './chart_filters/PieChartFilter';
import SpeedometerFilter from './chart_filters/SpeedometerFilter';
import StatusChartFilter from './chart_filters/StatusChartFilter';


function TableResults(props){

  const { rows1, handleViewForm, debug, total_ihr_potenzial_included, gesamtpotenzial_included } = props;

  const TableFilter = (props) => {

    let currentHeader = null;
    let backgroundColorRow = '#00000000';
    let countRowForBgColor = 0; //this will help start with a white row

     /*Dialog Box*/
    const [open, setOpen] = React.useState(false);
    const [dialogData, setDialogInformation] = React.useState({ title: "", information: "", status: "" });
    /*Dialog Box*/

    /* Dialog Box */
    const handleViewForm = (ziffer, description) => {
      setDialogInformation({ title: ziffer, information: description, status: "view" });
      setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };
    /* Dialog Box */

    return (<>
    
    <TableContainer component={Paper} sx={{borderRadius:0, maxHeight: 500}}>
        <Table size="small" aria-label="simple table" sx={{tableLayout: 'auto'}}>
          <TableHead>
            <TableRow sx={{
                                      backgroundColor: '#006400', 
                                      '& .MuiTableCell-root': { color: 'white', textAlign: 'right', whiteSpace: 'nowrap'},
                                      position: 'sticky',
                                      top: 0, // Ensures the header sticks to the top
                                      zIndex: 1, // Keeps it above other content when scrolling
                                    }}>
              <TableCell  sx={{textAlign: 'left!important', width: '190px'}}>GOÄ-Ziffer</TableCell>
              <TableCell  sx={{textAlign: 'left!important'}}>Leistung</TableCell>
              <TableCell  sx={{textAlign: 'right!important'}}>Anzahl</TableCell>
              <TableCell>Soll</TableCell> {/*Ideal Number of Treatments*/}
              <TableCell>Abweichung</TableCell> {/*Deviation*/}
              <TableCell>Abweichung in %</TableCell>{/*Deviation in %*/}
              {/*<TableCell>Abweichung zur Fachgruppe</TableCell>*/} {/*Abweichung zur Fachgruppe*/}
              <TableCell>Gesamtpotenzial in €</TableCell>
              <TableCell>Ihr Potenzial in €</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{color: '#134020',  position: 'relative', '& .MuiTableCell-root': { border:0 }}}>

            {
              rows1.map((row, index) => {
                const tableRows = [];

                //tableRows.push()

                let outputIdealNumberOfTreatments = parseFloat(row.ideal_number_of_treatments).toFixed(0);
                let outputDeviation = ((!isNaN(parseFloat(row.deviation))) ? parseFloat(row.deviation).toFixed(0).toString().replace('.', ',') : 0);
                let ouputDeviationInPercent = isNaN(parseFloat(row.deviation_in_percent)) ? '0' : parseFloat(row.deviation_in_percent).toFixed(2).toString().replace('.', ',') + '%';
                let outputIhrPotenzialInEuro =  (typeof row.ihr_potenzial_show === 'undefined') ? 
                                                             parseFloat(row.ihr_potenzial).toFixed(2).toString().replace('.', ',') : 
                                                               (row.ihr_potenzial_show) ? 
                                                                   (typeof row.ihr_potenzial === 'undefined') ? 0 : parseFloat(row.ihr_potenzial).toFixed(2).toString().replace('.', ',')
                                                               : '-';

                if((checkDeviationPercentStatus(row.deviation_in_percent_status).backgroundColor !== '#00000000')){
                  backgroundColorRow = checkDeviationPercentStatus(row.deviation_in_percent_status).backgroundColor;
                }else{
                  backgroundColorRow = (countRowForBgColor % 2 === 0 ? 'white' : '#F1F2F4')
                }

                tableRows.push(
                  <TableRow
                    key={row.id}
                    sx={{ 
                      '&:last-child td, &:last-child th': { border: 0 }, 
                      backgroundColor: backgroundColorRow,
                      '& .MuiTableCell-root': {color: checkDeviationPercentStatus(row.deviation_in_percent_status).color, textAlign: 'right'},
                      '& .MuiTableCell-root a': {color: checkDeviationPercentStatus(row.deviation_in_percent_status).color_href, textDecorationColor: checkDeviationPercentStatus(row.deviation_in_percent_status).color_href }
                  }} 
                  >
                    <TableCell sx={{textAlign: 'left!important'}}>
                      {
                        (row.description !== null) 
                        //(true)
                          ? <>
                              <Link 
                                  sx={{cursor:'pointer'}} 
                                  onClick={()=>{ handleViewForm(row.ziffer, row.description) }}
                              >{row.ziffer}
                              </Link>
                            </> : <>{row.ziffer}</>
                      }
                    </TableCell>
                    <TableCell sx={{textAlign: 'left!important'}}>
                      <Tooltip title={row.title} placement="top-start">
                        <TextField 
                            defaultValue={row.title} 
                            size="small" 
                            fullWidth  
                            InputProps={{
                              readOnly: true,
                              sx: {
                                    fontSize: '14px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      borderColor: 'transparent', // Removes the border color
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                      borderColor: 'transparent', // Removes border color on hover
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                      borderColor: 'transparent', // Removes border color on focus
                                    },
                                  }
                            }}
                            inputProps={{
                              style: {
                                padding: '3px',   // Inner padding of the input element
                                overflow: 'hidden',         // Hide overflowing content
                                whiteSpace: 'nowrap',       // Prevent text from wrapping
                                textOverflow: 'ellipsis',   // Show ellipsis (...) when text overflows
                                color: (checkDeviationPercentStatus(row.deviation_in_percent_status).color)
                              },
                            }}
                          />
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={{textAlign: 'right!important'}}>
                      {row.anzahl}
                    </TableCell>
                    <TableCell>
                      {outputIdealNumberOfTreatments}
                    </TableCell>
                    <TableCell>
                      {outputDeviation}
                    </TableCell>
                    <TableCell>
                      {ouputDeviationInPercent}
                    </TableCell>
                    {/*<TableCell>
                      {
                        (typeof row.abweichung_zur_fachgruppe_update === 'undefined') ? 
                            (Number.isNaN(parseFloat(row.abweichung_zur_fachgruppe))) ? 0 : parseFloat(row.abweichung_zur_fachgruppe).toFixed(2).toString().replace('.', ',') : 
                            (Number.isNaN(parseFloat(row.abweichung_zur_fachgruppe_update))) ? 0 : parseFloat(row.abweichung_zur_fachgruppe_update).toFixed(2).toString().replace('.', ',')
                      } %
                    </TableCell>*/} {/*Abweichung zur Fachgruppe*/}
                    <TableCell>
                      {
                        (typeof row.gesamtpotenzial === 'undefined') ? 0 : parseFloat(row.gesamtpotenzial).toFixed(2).toString().replace('.', ',')
                      }
                    </TableCell>
                    <TableCell>
                      {outputIhrPotenzialInEuro}
                    </TableCell>
                  </TableRow>
                )

                countRowForBgColor++;

                return tableRows;
              })
            }

            {
              /*rows1.map((row, index) => (
              ))*/
            }

          </TableBody>
        </Table>
      </TableContainer>

      <DialogOutput
                      fullScreen={false} // Set the value according to your requirement
                      open={open}
                      dialogData={dialogData}
                      handleClose={handleClose}
                      output={<ViewFormOutputStyle><ViewFormOutput description={dialogData.information} /></ViewFormOutputStyle>}
                      maxWidth='md'
            />

    </>)

  }

  return (<>

    <Grid container spacing={2}>
      <Grid item xs={4}>
        <PieChartFilter total_ihr_potenzial_included={total_ihr_potenzial_included} gesamtpotenzial_included={gesamtpotenzial_included} />
      </Grid>
      <Grid item xs={4}>
        <SpeedometerFilter total_ihr_potenzial_included={total_ihr_potenzial_included} gesamtpotenzial_included={gesamtpotenzial_included} />               
      </Grid>
      <Grid item xs={4}>
        <StatusChartFilter rows={rows1} />
      </Grid>
    </Grid>
    
    <br/>

    <TableFilter />

    <br/><br/>

  </>)

}

function TableSeparate(props){

  const { 
          rows1, 
          setRows1,
          anzahl1,
          inputCalculationData, 
          setInputCalculationData, 
          handleViewForm,
          filter1,
          title1, 
          title2, 
          info 
  } = props;

  //const filteredRows = rows1?.filter((row) => filter1.includes(row.ziffer)) // Check for rows1 and id property
   //                                                  .sort((a, b) => (a.ziffer > b.ziffer ? 1 : -1)); //Order by ASC

  //const filteredRows = rows1?.filter((row) => filter1.includes(row.ziffer)) // Check for rows1 and id property
  //                                                  .sort((a, b) => (a.ziffer > b.ziffer ? 1 : -1)); //Order by ASC
  
  const filteredRows = filterAndSortInputRows(rows1, filter1);
  
  return (<>
  
    <TableContainer component={Paper} sx={{borderRadius:0}}>
      <Table size="small" aria-label="simple table" sx={{ border: '1px solid #949494' }}>
        <TableHead>
          {
            (title1 !== "" && title2 !== "") ? 
              <TableRow sx={{backgroundColor: '#006400'}}>
                <TableCell sx={{ border: '1px solid #949494', color: '#ffffff', textAlign: 'center' }}>{(title1 !== "") ? title1: ""} </TableCell>
                <TableCell sx={{ border: '1px solid #949494', color: '#ffffff', textAlign: 'center' }}>{(title2 !== "") ? title2: ""}</TableCell>
              </TableRow> : ""
          }

          {                
            (info !== "") ?
              <TableRow sx={{backgroundColor: '#CAE3B6'}}>
                <TableCell colSpan={2} sx={{color: '#134020', border: '1px solid  #949494'}}>
                  <div dangerouslySetInnerHTML={{ __html: info }} />
                </TableCell>
              </TableRow> : ""
          }
        </TableHead>
        <TableBody>
          {filteredRows.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: '1px solid #949494' } }}
            >
              <TableCell width={"50%"} sx={{ border: '1px solid #949494', textAlign: 'center' }}>
                {
                  (row.description !== null) 
                    ? <>
                        <Link 
                            sx={{cursor:'pointer'}} 
                            onClick={()=>{ handleViewForm(row.ziffer, row.description) }}
                        >{row.ziffer}
                        </Link>
                      </> : <>{row.ziffer}</>
                }
              </TableCell> {/*EBM-Ziffer*/}
              <TableCell width={"50%"} sx={{ border: '1px solid #949494', textAlign: 'center' }}>
                <InputFieldTable1 
                  row={row}
                  rows={rows1}
                  setRows={setRows1}
                  anzahl={anzahl1}
                  index={row.ziffer}
                  inputCalculationData={inputCalculationData} 
                  setInputCalculationData={setInputCalculationData}
                  handleViewForm={handleViewForm}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

  </>);
}

function TableCompute1(props) {
  
  const { 
          rows, 
          anzahl,
          handleViewForm,
          debug
        } = props;


  const [inputPatienten, setInputPatienten] = useState({ patienten: '' });
  
  const [inputCalculationData, setInputCalculationData] = useState({ });

  const [anzahl1, setAnzahl1] = useState(anzahl);

  const [rows1, setRows1] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [dialogData, setDialogInformation] = React.useState({ title: "", information: "", status: "" });

  const [gesamtpotenzial_included, setGesamtpotenzialIncluded] = useState(0);

  const [total_ihr_potenzial_included, setTotalIhrPotencialIncluded] = useState(0);

  useEffect(() => {

    const filteredRows = filterAndSortRows(rows);

    setRows1(filteredRows); 

    setAnzahl1(anzahl);

  }, [rows]);

  function onCalculateEachRowAbove(){

    if(!debug){
      setDialogInformation(
        { title: '', 
          information: 'Bitte warten!', 
          status: "loading" 
        }
      );

      setOpen(true);
    }

    executeCalculation();
    
    let ihrPotencialIncluded = countIhrPotencialIncluded(rows1, inputCalculationData);

    /*console.log("ihrPotencialIncluded")
    console.log(ihrPotencialIncluded)*/

    setTotalIhrPotencialIncluded(ihrPotencialIncluded);

    let gesamtpotenzial_included = countGesamtpotenzialIncluded(rows1, inputCalculationData);

    /*console.log("gesamtpotenzial_included")
    console.log(gesamtpotenzial_included)*/

    setGesamtpotenzialIncluded(gesamtpotenzial_included);

    /*console.log(inputCalculationData)
    console.log("inputCalculationData")*/
    
    if(!debug){

      setTimeout(() => {
        setOpen(false);

        setDialogInformation(
          { title: '', 
            information: '', 
            status: "view" 
          }
        );

      },500)

      setTimeout(() => {
        setOpen(true);
      },501)
    }
  }

  function executeCalculation(){

    const updatedRows = rows1.map((row, index) => {
      
      let input_anzahl = (inputCalculationData[`anzahl_${row.ziffer}`] === '') ? 0 : parseFloat(inputCalculationData[`anzahl_${row.ziffer}`]);

      row.anzahl = input_anzahl;
      
      let patienten = (inputPatienten.patienten === '') ? 0 : parseFloat(inputPatienten.patienten);

      //row.verhaeltnis = (parseFloat(input_anzahl) / patienten) * 100;
      //row.verhaeltnis = (parseFloat(inputCalculationData.anzahl) / patienten) * 100;

      //idea_number_of_treatments=patienten*(row.coefficent / 100)
      let ideal_number_of_treatments = patienten * percentToDecimal(parseFloat(row.coefficient));

      //abweichung_zur_fachgruppe=-(1-anzahl_input/idea_number_of_treatments)
      //abweichung_zur_fachgruppe is deviation

      let abweichung_zur_fachgruppe = -(1-input_anzahl/ideal_number_of_treatments)

      abweichung_zur_fachgruppe = abweichung_zur_fachgruppe * 100;

      row.abweichung_zur_fachgruppe = abweichung_zur_fachgruppe;
      

      let deviation = (input_anzahl - ideal_number_of_treatments);

      row.deviation = deviation;

      let deviation_in_percent = abweichung_zur_fachgruppe;

      row.deviation_in_percent = deviation_in_percent;


      row.deviation_in_percent_status = checkDeviationPercentColor(deviation_in_percent, input_anzahl);

      row.ideal_number_of_treatments = ideal_number_of_treatments;

      let factor = (row.factor === null) ? 0 : parseFloat(row.factor);

      row.gesamtpotenzial = ideal_number_of_treatments * factor;

      row.ihr_potenzial = ideal_number_of_treatments * factor - parseFloat(input_anzahl) * factor;

      let ihr_potenzial_show = 1;

      if(parseInt(input_anzahl) <= parseInt(ideal_number_of_treatments)){
        ihr_potenzial_show = 1;
      }else{
        ihr_potenzial_show = 0;
      }

      //let abweichung = (input_anzahl - ideal_number_of_treatments);

      row.ihr_potenzial_show = ihr_potenzial_show;

      return { ...row };
    });
    
    setRows1(updatedRows);

  }

  const handleClose = () => {
    setOpen(false);
  };

  /*console.log("inputCalculationData.anzahl")
  console.log(inputCalculationData.anzahl)

  console.log("inputCalculationData.patienten")
  console.log((inputCalculationData.patienten === '') ? 0 : inputCalculationData.patienten)

  
  */

  /*console.log("rows1")
  console.log(rows1)*/

  return (
    <>
        <InputFields 
                        inputPatienten={inputPatienten}
                        setInputPatienten={setInputPatienten}
                   />
                   <br /><br /> 

        <Grid container spacing={2}>
          <Grid item xs={3} sx={{position: 'relative'}}>
              <TableSeparate 
                rows1={rows1}
                setRows1={setRows1}
                anzahl1={anzahl1}
                inputCalculationData={inputCalculationData} 
                setInputCalculationData={setInputCalculationData}
                handleViewForm={handleViewForm}
                filter1={['1','3','4','5','7','8','21','23','24','34','60','75','78','80','100','245','250','252','272','291','297','298','375','383','401','403','404','405','410']}
                title1="GOÄ-Ziffer"
                title2="Anzahl"
                info=""
              />
          </Grid>
          <Grid item xs={3} sx={{position: 'relative'}}>
              <TableSeparate 
                rows1={rows1}
                setRows1={setRows1}
                anzahl1={anzahl1}
                inputCalculationData={inputCalculationData} 
                setInputCalculationData={setInputCalculationData}
                handleViewForm={handleViewForm}
                filter1={['415','418','420','442','490','602','705','804','1001','1002','1006','1008','1070','1075','1096','1105','2000','2006','2007','2008','2009','2400','2401','2403','2430','3508','3509','3511','3517']}
                title1="GOÄ-Ziffer"
                title2="Anzahl"
                info=""
              />
          </Grid>
          <Grid item xs={3} sx={{position: 'relative'}}>
              <TableSeparate 
                rows1={rows1}
                setRows1={setRows1}
                anzahl1={anzahl1}
                inputCalculationData={inputCalculationData} 
                setInputCalculationData={setInputCalculationData}
                handleViewForm={handleViewForm}
                filter1={['3529','3532','3541','3550','3551','3555','3557','3558','3560','3561','3562','3563','3564','3565','3573','3574','3575','3581','3583','3585','3587','3588','3592','3594','3595','3597','3598','3714','3736']}
                title1="GOÄ-Ziffer"
                title2="Anzahl"
                info=""
              />
            </Grid>
            <Grid item xs={3} sx={{position: 'relative'}}>
              <TableSeparate 
                rows1={rows1}
                setRows1={setRows1}
                anzahl1={anzahl1}
                inputCalculationData={inputCalculationData} 
                setInputCalculationData={setInputCalculationData}
                handleViewForm={handleViewForm}
                filter1={['3741','3747','3911','4020','4030','4042','4069','4138','4405','4419','4506','4520','4525','4548','4610','4711','4715','4722','4749','4850','5373','269A','A','B','D','G','H']}
                title1="GOÄ-Ziffer"
                title2="Anzahl"
                info=""
              />
              <br/> 
              
              <Box sx={{textAlign: 'right'}}>
                <Button variant="contained" onClick={()=>{ onCalculateEachRowAbove() }}>Jetzt berechnen</Button>  
              </Box>
              

            </Grid>
        </Grid>

        {
          (debug) ? 
            <TableResults rows1={rows1} handleViewForm={handleViewForm} debug={debug} total_ihr_potenzial_included={total_ihr_potenzial_included} gesamtpotenzial_included={gesamtpotenzial_included}/> : ''
        }

        <DialogOutput
            fullScreen={true} // Set the value according to your requirement
            open={open}
            dialogData={dialogData}
            handleClose={handleClose}
            backgroundColor='#F1F2F4'
            output={
                      <TableResults rows1={rows1} handleViewForm={handleViewForm} total_ihr_potenzial_included={total_ihr_potenzial_included} gesamtpotenzial_included={gesamtpotenzial_included}/>
                  }
        />          
      
        <br /><br />
          
    
    </>
  );
}

function InputFieldTable1(props){
  
  const { row, rows, setRows, patienten, anzahl, index, inputCalculationData, setInputCalculationData } = props;

  //onst [inputNumber, setInputNumber] = useState({});

  const [enableValidation, setEnableValidation] = useState(false);  

  const [hasTyped, setHasTyped] = useState(false);

  const [errorInputCalculation, setErrorInputCalculation] = useState({});

  //const [defaultValue, setDefaultValue] = useState(anzahl);
  //let defaultValue = anzahl;

  //const defaultValue = 0;
  const currentValue = inputCalculationData[`anzahl_${index}`] || ''; 

  function validation(inputCalculationData, validate) {

    let error = { anzahl: '' }

    if (validate) {
        error.anzahl = (inputCalculationData[`anzahl_${index}`] === "") ? "anzahl ist erforderlich" : "";
    }

    setErrorInputCalculation(error)

    if (error.anzahl === "") {
        return false;
    } else {
        return true;
    }
  }

  function changeInputCalculationData(e) {
   
    const { name, value } = e.target;
  
     // Remove non-numeric characters except period (.)
     let newValue = value.replace(/[^0-9\.]/g, '');
  
    //console.log("name")
    //console.log(name)

     // If the input starts with '0' and the user types for the first time, remove the leading zero
     if (!hasTyped && newValue.startsWith('0') && newValue.length > 1) {
      newValue = newValue.substring(1);
      setHasTyped(true); // Mark that the user has started typing
    }

    //setinputCalculationData({ ...inputCalculationData, [name]: newValue });
    setInputCalculationData(prevState => ({ ...prevState, [name]: newValue }));
    
    //console.log(inputCalculationData);
    //setInputNumber(inputCalculationData);
  }

  function onInputCalculationValidation(){
    validation(inputCalculationData, enableValidation);
  }

  function onInputCalculationValidationFocusOut() {
    validation(inputCalculationData, enableValidation);
  }

  useEffect(() => {
    
  }, [rows]);

  useEffect(() => {
    
    //setInputNumber(inputCalculationData)

    setInputCalculationData(prevState => ({ ...prevState, [`anzahl_${index}`]: anzahl }));

  }, [anzahl]);

  return (<>
    
              <TextField
                      required
                      margin="dense"
                      id={`anzahl_${index}`}
                      name={`anzahl_${index}`}
                      type="text"
                      variant="filled"
                      value={currentValue}
                      size="small"
                      hiddenLabel
                      sx={{marginTop:0, '& .MuiInputBase-input': {
                                          textAlign: 'center', // Change this to 'left' or 'right' for other alignments
                                          borderRadius: 0,
                                        },
                                        '& .MuiInputBase-root':{
                                          borderTopLeftRadius: 0,
                                          borderTopRightRadius: 0
                                        },
                                        '& .MuiInputBase-input.Mui-disabled': {
                                          WebkitTextFillColor: 'black',
                                          color: 'black',
                                        },
                          }}
                      onChange={changeInputCalculationData}
                    />
  </>);

}

function InputFields(props){

  const { inputPatienten, setInputPatienten } = props;

  const [enableValidation, setEnableValidation] = useState(false);  

  const [errorInputCalculation, setErrorInputCalculation] = useState({});

  async function onCalculate() {
    
    setEnableValidation(true);

    if (!validation(inputPatienten, true)) {

        /*console.log("inputPatienten");
        console.log(inputPatienten);*/

        //setInputNumber(inputPatienten);

        //getGOÄ_ZifferAbrechnungskompassData();

        /*setDisplayResult(true);

        getGOÄ_ZifferAbrechnungskompassData()
        .then(() => {
          setDisplayResult(false)
        })
        .catch(error => {
            console.log('Error fetching user data:', error);
        });*/
    }

    //setEnableValidation(true);
    
    //getGOÄ_ZifferAbrechnungskompassData();
    
  }

  function onInputCalculationValidation(){
    validation(inputPatienten, enableValidation);
  }

  function validation(inputPatienten, validate) {

    let error = { patienten: "" }

    if (validate) {
        error.patienten = (inputPatienten.patienten === "") ? "Patienten ist erforderlich" : "";
    }

    setErrorInputCalculation(error)

    if (error.patienten === "") {
        return false;
    } else {
        return true;
    }
  }

  function onInputCalculationValidationFocusOut() {
    validation(inputPatienten, enableValidation);
  }

  function changeInputCalculationData(e) {
   
    const { name, value } = e.target;
  
    const newValue = value.replace(/[^0-9\.]/g, ''); // Only allows numbers, period(.) and comma(,)
  
    setInputPatienten({ ...inputPatienten, [name]: newValue });
    //console.log(inputPatienten);

    //setInputNumber(inputPatienten);
  }

  return (<>

                  <Typography variant="h5" component="h1" gutterBottom sx={{color: '#014017'}}>
                    Bitte geben Sie die Anzahl Ihrer PP-Patientinnen und die Anzahl der jeweiligen GOÄ-Ziffern an: 
                  </Typography>
  
                  <TextField
                    required
                    margin="dense"
                    id="patienten"
                    label="Privatpatientinnen"
                    name="patienten"
                    type="text"
                    variant="outlined"
                    value={inputPatienten.patienten}
                    error={(enableValidation) ? (errorInputCalculation.patienten !== "") ? true : false : false}
                    helperText={errorInputCalculation.patienten}
                    onChange={changeInputCalculationData}
                    onKeyUp={onInputCalculationValidation}
                    onBlur={onInputCalculationValidationFocusOut}
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'black',
                        color: 'black',
                      },
                    }}
                   />
  </>)

}

export default function GOÄ_Abrechnungskompass() {

  function createData(
                      id,
                      ziffer,
                      coefficient, 
                      factor,
                      title,
                      description
                    ) {
    return {
        id,
        ziffer,
        coefficient,
        factor,
        title,
        description
    };
  }

  const location = useLocation();

  const [debug, setDebug] = useState(false);

  const [inputNumber, setInputNumber] = useState({ patienten: '', anzahl: '0' });

  const [listDataFromDB, setRowDataFromDB] = useState([]);

  const [rows, setRows] = useState([]);

  const [totalRowsCount, setTotalRowsCount] = useState(0);


  /*Dialog Box*/
  const [open, setOpen] = React.useState(false);
  const [dialogData, setDialogInformation] = React.useState({ title: "", information: "", status: "" });
  /*End of Dialog Box*/

  const getGOÄ_ZifferAbrechnungskompassData = async () => {

    //console.log("inputCalculationData")
    //console.log(inputCalculationData)

    try {
        const JSONData = await DatabaseService.getGOÄ_Abrechnungskompass();
        if (JSONData && JSONData.rows) {
          setRowDataFromDB(JSONData.rows); // Set the user data array to listAllUsers
        }

        if (JSONData && JSONData.totalCount) {
            setTotalRowsCount(JSONData.totalCount); // Set the user data array to listAllUsers
            //setRowsPerPage(JSONData.totalCount)
        }

    } catch (error) {
        console.error('Error fetching data:', error);
    }

    //setLoading(false); // Set loading to false after data is fetched
  }

  useEffect(() => {
    
      getGOÄ_ZifferAbrechnungskompassData()
        .then(() => {})
        .catch(error => {
            console.log('Error fetching data:', error);
        });
    
  }, []);

  useEffect(() => {
    
    // Map listAllUsers to create rows and update the state
    const updatedRows = listDataFromDB.map(item => createData(
        item.id,
        item.ziffer,
        item.coefficient,
        item.factor,
        item.title,
        item.description
    ));

    // Update the rows state
    setRows(updatedRows);

  }, [listDataFromDB]);

  useEffect(() => {

    if(new URLSearchParams(location.search).get('debug') !== null) {
      setDebug(true);  
    }

  }, [])

  /* Dialog Box */
  const handleViewForm = (gop, description) => {
      setDialogInformation({ title: "", information: description, status: "view" });
      setOpen(true);
  }

  const handleClose = () => {
      setOpen(false);
  };
  /* Dialog Box */

  

  return (
            <MuiDrawer>
                  
                  <TableCompute1 
                        rows={rows} 
                        handleViewForm={handleViewForm}
                        anzahl={inputNumber.anzahl}
                        debug={debug}
                   />

                    <DialogOutput
                      fullScreen={false} // Set the value according to your requirement
                      open={open}
                      dialogData={dialogData}
                      handleClose={handleClose}
                      output={<ViewFormOutputStyle><ViewFormOutput description={dialogData.information} /></ViewFormOutputStyle>}
                      maxWidth='md'
                   />
        
            </MuiDrawer>
          );
}