import { Box, Grid, IconButton, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import Controls from '../components/controls/Controls';

import * as DatabaseService from '../services/DatabaseService';

import LoginSignupContainer from '../components/Template/LoginSignup';

/* userHistory -> useNavigate in ver 6 of react-router-dom*/
//import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';

import { useAuth } from '../contexts/AuthContext';
import ContactInfoForm, { validateContactInfoForm } from '../components/ContactInfoForm';
import setNestedField from '../utils/setNestedField';
import { QRCodeCanvas } from 'qrcode.react';

const ConfigureAuthenticatorApp = ({authenticator, setAuthenticator}) => {
    return (
        <Box sx={{ my: 2, p: '0 !important' }}>
            <Typography variant="subtitle1" gutterBottom>
                Scannen Sie diesen QR-Code mit Ihrer Authenticator-App:
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                <QRCodeCanvas value={authenticator.otpAuthUrl} size={200} />
            </Box>
            <Typography variant="body2" gutterBottom>
                Oder geben Sie dieses Geheimnis manuell ein: {authenticator.secret}
            </Typography>
            <TextField
                fullWidth
                label="Einmalcode eingeben"
                value={authenticator.otp || ''}
                onChange={(e) => setAuthenticator(prev => ({ ...prev, otp: e.target.value }))}
                margin="normal"
            />
        </Box>
    )
}

export default function Signup() {
    const [step, setStep] = useState('credentials'); // 'credentials', 'contactInfo',
    const { signup, currentUser } = useAuth();
    const [registrationData, setRegistrationData] = useState({
        username: '',
        password: '',
        password1: '',
        email: '',
        fname: '',
        lname: '',
        registrationCode: '',
        profile: {
            salutation: '',
            title: '',
            phone: '',
            currentStatus: '',
            establishmentDate: null,
            practiceType: '',
            insuranceSeats: '',
            otherStatus: '',
            practiceManagementSystem: '',
            address: '',
            postalCodeCity: '',
            website: '',
            comments: '',
            isFullMembership: false,
            bsnr: '',
            lanr: '',
            iban: '',
            accountHolder: '',
            billingDetails: '',
            sendInvoiceEmail: false,
            billingEmail: '',
        },
        acceptTerms: false,
    });
    const [authenticator, setAuthenticator] = useState({
        otp: '',
        secret: '',
        otpAuthUrl: '',
    });
    const [errorRegistration, setErrorRegistration] = useState({});
    const [enableValidation, setEnableValidation] = useState(false);
    const [alertRegistration, setAlertRegistration] = useState({text: '', appear: false, severity: ''});
    const [registerButtonDisabled, setRegisterButtonDisabled] = useState(false);
    const [emailExist, setEmailExist] = useState(0);
    const [usernameExist, setUsernameExist] = useState(0);

    //const history = useNavigate();
    const navigate = useNavigate();

    const checkEmail = async (data) => {
        return await DatabaseService.checkEmail(data);
    }

    const checkUsername = async (data) => {
        return await DatabaseService.checkUsername(data);
    }

    function changeRegistrationData(e){
        const { name, value } = e.target;
        setRegistrationData(setNestedField(registrationData, name, value));
    }

    async function nextStep() {
        if (!validation(registrationData, ['username', 'password', 'password1', 'registrationCode'])) {
            if (await isCodeValid()) {
                setStep('contactInfo');
                return handleConfigureAuthenticator();
            }
            return setErrorRegistration(prev => ({ ...prev, registrationCode: 'Registrierungscode ist ungültig oder wurde bereits verwendet' }));
        }
    }

    function isCodeValid() {
        return DatabaseService.checkRegistrationCodeValid(registrationData.registrationCode);
    }

    async function onRegistration(){
        setAlertRegistration({ appear: false, text: '', severity: '' });

        setEnableValidation(true);

        if(!validation(registrationData, true)){

            setRegisterButtonDisabled(true);

            const response = await signup({...registrationData, otp: authenticator.otp });
            const result = await response.json();
            if (!result.success) {
                setRegisterButtonDisabled(false);
                return setAlertRegistration(
                    {
                        text: result.message,
                        appear: true,
                        severity: 'error'
                    }
                )
            }

            setAlertRegistration(
                {
                    text: result.message,
                    appear: true,
                    severity: 'success'
                }
            )

            setTimeout(() => {
                //history.push('/login')
                navigate('/login')
            }, 2000)

        }

    }

    function onRegistrationValidation(){

        checkEmail(registrationData.email).then(r => r.json()).then(result => {
            setEmailExist(result.rows);
        });

        checkUsername(registrationData.username).then(r => r.json()).then(result => {
            setUsernameExist(result.rows);
        });

        validation(registrationData, enableValidation);
    }

    function onRegistrationValidationFocusOut(){

        validation(registrationData, enableValidation);

    }

    /**
     *
     * @param {*} validate false to disable validation, true to enable validation, array to validate only fields in array
     * @returns
     */
    function validation(registrationData, validate){

        let error = { fname: "", lname: "", email: "", username: "", password: "", password1: "", acceptTerms: "" }

        if(validate){
            error.email = (/$^|.+@.+..+/).test(registrationData.email)
                        ? (emailExist) ? "E-Mail existiert bereits" : ""
                            : "E-Mail ist ungültig";

            error.username = (registrationData.username === "")
                ? "Benutzername ist erforderlich" : (usernameExist) ? "Benutzername existiert bereits" : "";

            error.password = (registrationData.password === "")
                ? "Passwort ist erforderlich" :
                    (registrationData.password.length < 8) ? "Passwort muss 8 Zeichen lang sein"
                        : "";

            error.password1 = (registrationData.password1 === "")
                ? "Passwortbestätigung ist erforderlich" :
                    (registrationData.password !== registrationData.password1) ?  "Passwörter stimmen nicht überein"
                        : "";

            error.registrationCode = (registrationData.registrationCode === "") ? "Registrierungscode ist erforderlich" : "";

            error.acceptTerms = (registrationData.acceptTerms === false) ? "Sie müssen die Nutzungsbedingungen akzeptieren" : "";

            error = {...error, ...validateContactInfoForm(registrationData)}

            // remove fields not in validate array
            if (Array.isArray(validate)) {
                Object.keys(error).forEach(key => {
                    if (!validate.includes(key)) {
                        error[key] = '';
                    }
                });
            }

            if (error.acceptTerms) {
                setAlertRegistration({
                    text: error.acceptTerms,
                    appear: true,
                    severity: 'error'
                });
            }
        }

        setErrorRegistration(error)

        return Object.values(error).some(v => v !== '');

    }

    const handleConfigureAuthenticator = async () => {
        const response = await fetch('/api/user/2fa/configure-register', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ method: 'app', username: registrationData.username })
        }).catch((err) => ({
            message: err.response?.data?.message || 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.'
        }));
        const { secret, url, message } = await response.json();
        if (!secret) {
            return setAlertRegistration({
                text: message,
                appear: true,
                severity: 'error'
            });
        }
        setAuthenticator(prev => ({ ...prev, secret, otpAuthUrl: url }));
    }

    return (
        <>
            <LoginSignupContainer>
                <Box sx={{ position: 'relative' }}>
                    {
                        (step === 'contactInfo') && (
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="back"
                                onClick={() => setStep('credentials')}
                                sx={{ position: 'absolute', left: 0 }}
                            >
                                <ArrowBack />
                            </IconButton>
                        )
                    }
                    <Typography variant="h6" component="div" align="center">
                        Benutzer-Registrierung
                    </Typography>
                </Box>
                {
                    /*(currentUser) ? history.push('/') : ''*/
                    (currentUser) ? navigate('/') : ''
                }
                {
                    (step === 'credentials') && (
                        <Grid container item spacing={2}>
                            <Grid item xs={12}>
                            </Grid>

                            <Grid item xs={12}>
                                <Controls.Input
                                    label="Benutzername"
                                    name="username"
                                    value={registrationData.username}
                                    onChange={changeRegistrationData}
                                    autoComplete="off"
                                    error={errorRegistration.username}
                                    onKeyUp={onRegistrationValidation}
                                    onBlur={onRegistrationValidationFocusOut}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controls.Input
                                    label="Passwort"
                                    name="password"
                                    value={registrationData.password}
                                    onChange={changeRegistrationData}
                                    autoComplete="off"
                                    type="password"
                                    error={errorRegistration.password}
                                    onKeyUp={onRegistrationValidation}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controls.Input
                                    label="Passwort bestätigen"
                                    name="password1"
                                    value={registrationData.password1}
                                    onChange={changeRegistrationData}
                                    autoComplete="off"
                                    type="password"
                                    error={errorRegistration.password1}
                                    onKeyUp={onRegistrationValidation}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controls.Input
                                    label="Registrierungscode"
                                    name="registrationCode"
                                    value={registrationData.registrationCode}
                                    onChange={changeRegistrationData}
                                    autoComplete="off"
                                    error={errorRegistration.registrationCode}
                                    onKeyUp={onRegistrationValidation}
                                    onBlur={onRegistrationValidationFocusOut}
                                />
                            </Grid>

                            <Grid item xs={12}>
                            <Controls.Button
                                text="Weitermachen"
                                color="primaryGreen"
                                onClick={nextStep}
                                size="large"
                                disabled={registerButtonDisabled}
                            />
                            </Grid>
                        </Grid>
                    )
                }
                {
                    (step === 'contactInfo') && (
                        <>
                            <ContactInfoForm
                                formData={registrationData}
                                handleChange={changeRegistrationData}
                                formError={errorRegistration}
                            />
                            <ConfigureAuthenticatorApp authenticator={authenticator} setAuthenticator={setAuthenticator} />
                            <Grid item xs={12}>
                            <Controls.Checkbox
                                name="acceptTerms"
                                label={
                                    <span>Hiermit bestätige ich, dass ich die <a target="_blank" rel="noreferrer" href="https://www.gynsuccess.de/nutzungsbedingungen/">Nutzungsbedingungen</a> gelesen habe und mit diesen einverstanden bin</span>
                                }
                                value={registrationData.acceptTerms || false}
                                onChange={changeRegistrationData}
                            />
                            </Grid>
                            <Controls.Alert sx={{ margin: '12px 0' }} severity={alertRegistration.severity} appear={alertRegistration.appear} text={alertRegistration.text} />
                            <Grid item xs={12}>
                            <Controls.Button
                                text="Registrieren"
                                color="primaryGreen"
                                onClick={onRegistration}
                                size="large"
                                disabled={registerButtonDisabled}
                            />
                            </Grid>
                        </>
                    )
                }
            </LoginSignupContainer>
        </>
    );
}
