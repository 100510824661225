import { useState, useEffect } from 'react';
import * as DatabaseService from './DatabaseService'; // Adjust the path based on your project structure

const userSessionMembershipCheck = (userSession) => {
  let output = 2;

  // Ensure that we have valid data for role_id and upgrade_features
  if (!userSession || typeof userSession.user_role_id === 'undefined') {
    return output; // Return the default output (2) if data is missing
  }

  const upgrade_features = !!userSession.upgrade_features;
  const role_id = parseInt(userSession.user_role_id, 10);

  if (role_id === 1 || role_id === 4) {
    // Admin can see all the features
    output = 1;
  } else if (role_id === 2) {
    // If not a full member, show only the chart and lock
    output = 2;
  } else if (role_id === 5 && !upgrade_features) {
    // If full membership is true but upgrade features is false, show chart and info on the table
    output = 3;
  } else {
    // If the user has full membership and upgrade features, show all the features
    output = 1;
  }

  return output;
};

export const useSingleUserData = () => {
  const [listSingleUser, setSingleUserData] = useState(null); // Initialize as null to avoid premature checks
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [userStatus, setUserStatus] = useState(2);
  const [userRoleID, setUserRoleID] = useState(0);

  const getSingleUserData = async () => {
    try {
      const singleUserData = await DatabaseService.getSingleUserData();
      if (singleUserData && Array.isArray(singleUserData.rows) && singleUserData.rows.length > 0) {
        setSingleUserData(singleUserData.rows[0]); // Set the user data array to listSingleUser
      }

      if (singleUserData && typeof singleUserData.totalCount === 'number') {
        setTotalRowsCount(singleUserData.totalCount); // Set the user data array to totalRowsCount
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    getSingleUserData().catch(error => {
      console.error('Error fetching user data:', error);
    });
  }, []);

  useEffect(() => {
    if (listSingleUser) {
      // Ensure listSingleUser has valid data before calling userSessionMembershipCheck
      const userStatusOutput = userSessionMembershipCheck(listSingleUser);
      setUserStatus(userStatusOutput);

      setUserRoleID(listSingleUser.user_role_id);
    }
  }, [listSingleUser]);

  return { userRoleID, userStatus, totalRowsCount };
};
