import React, { useEffect, useState } from 'react';
//import { makeStyles } from '@material-ui/core/styles';
//import Grid from '@material-ui/core/Grid';
import Grid from '@mui/material';
import * as DatabaseService from '../../services/DatabaseService';
//import CategoryCounter from '../../components/CategoryCounter';
//import BarChart from '../../components/charts/BarChart';
//import processChartData from '../../components/charts/processChartData';

import MuiDrawer from '../../components/MuiDrawer';

import ExtractedData from './DBTabs';

import { useAuth } from '../../contexts/AuthContext';

/*const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  canvasSize:{
    [theme.breakpoints.up(981)]: {
      height: '30vw',
      width: '100%',
    },
    [theme.breakpoints.down(981)]: {
      height: '41vw',
    },
    [theme.breakpoints.down(960)]: {
      height: '46vw',
    },
    [theme.breakpoints.down(768)]:{
      height: '59vw',
    },
    [theme.breakpoints.down(526)]:{
      height: '70vw',
    },
    [theme.breakpoints.down(413)]:{
      height: '78vw',
    },
  },
  gridCategoryCounterChart:{
    marginTop: '11px'
  },
  gridApplicationCounter:{
    [theme.breakpoints.down(600)]:{
      '& .MuiGrid-item':{
        paddingBottom: 0
      }
    }
  }
}));*/

export default function MainPage() {

  const { login, currentUser, currentRole, getSession } = useAuth();

  //const [listCountAllApplications, setCountAllApplications] = useState([])

  //const [listMonthlyTotalApplications, setMonthlyTotalApplications] = useState([]);

  //const getDataCountAllApplications = async () => { setCountAllApplications(await DatabaseService.getData("applications_countall")); }

  //const getDataMonthlyTotalApplications = async () => { setMonthlyTotalApplications(await DatabaseService.getData("monthly_totalapplications")); }

  /*useEffect(() => {
    getDataCountAllApplications();
    getDataMonthlyTotalApplications();
  }, []);*/

  //const barChart = processChartData(listMonthlyTotalApplications, 'bar');

  return (
            <MuiDrawer>

          {(currentRole !== "Subscriber" && currentRole !== "Author") ? <><ExtractedData /></> : "Welcome " + currentRole}
                
            </MuiDrawer>
          );
}

/*export default function MainPage1() {

  const classes = useStyles();

  const [listCountAllApplications, setCountAllApplications] = useState([])

  const [listMonthlyTotalApplications, setMonthlyTotalApplications] = useState([]);

  const getDataCountAllApplications = async () => { setCountAllApplications(await DatabaseService.getData("applications_countall")); }

  const getDataMonthlyTotalApplications = async () => { setMonthlyTotalApplications(await DatabaseService.getData("monthly_totalapplications")); }

  useEffect(() => {
    getDataCountAllApplications();
    getDataMonthlyTotalApplications();

  }, []);

  const barChart = processChartData(listMonthlyTotalApplications, 'bar');

  function CategoryApplicationCounter() {
    return (
      <>
          { 
              listCountAllApplications.map((item, index) => (
                  <Grid item xs={12} md={6} lg={3} key={item.id}>
                          <CategoryCounter  
                              urlID={item.id} 
                              totalCount={item.count}
                              year={item.yr}
                              title={item.name}   
                              bgColorID={index}
                          />
                  </Grid>
              ))
          }
      </>
    );
  }

  //Chart
  function CategoryCounterChart(){
    return (
      <> 
        <Grid item xs={12}>      
          <BarChart 
            height="30vw"
            width="100%"
            labels={barChart.labels}
            datasets={barChart.datasets}
            headerTitle="Monthly Total Applications in 2021" 
            classNameCanvasSize={classes.canvasSize} 
          />               
        </Grid>
      </>
    );
  }

  return (

    <MuiDrawer>
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid container item spacing={3} className={classes.gridApplicationCounter}>
            <CategoryApplicationCounter />
          </Grid>
          
          <Grid container item spacing={3} className={classes.gridCategoryCounterChart}>
              <CategoryCounterChart />
          </Grid>
        </Grid>
      </div>
    </MuiDrawer>
    
  );
}*/